import React, { useState, useEffect, useRef } from 'react';

const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const Masonry = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [shuffledGalleryData, setShuffledGalleryData] = useState([]);
  const modalRef = useRef();

  const galleryData = [
    {
      id: 1,
      title: 'Recruiting Brochure',
      images: ['/images/rec-brochure.jpg', 'https://placehold.co/400x500'],
      description: 'The Recruiting Department is the lifeblood of the company and one of the most important tools is their brochures. I was part of the team that designed this brochure for both doctors and APPs.',
      shortDescription: 'US Acute Care Solutions, Recruiting Department',
      company: 'US Acute Care Solutions',
      link: '/images/RecruitingBrochure_DOC_2019.pdf',
      buttonText: 'See Full Brochure',
    },
    {
      id: 2,
      title: 'Life Groups Branding',
      images: ['/images/life-groups-logo.jpg'],
      description: 'Rittman Grace Brethren Church approached me for a new logo for their Small Groups program. Small groups are an important part of the growth of a church. That is why I thought it was important to incorporate the seedling in the design.',
      shortDescription: 'Short description for image 2.',
      company: 'Rittman Grace Brethren Church, Rittman, OH',
    },
    {
      id: 3,
      title: 'EMS Providers ',
      images: ['/images/EM-Banners.jpg', '/images/Banner-1.jpg'],
      description: 'These were large format outdoor banners that were created for EMS Week and EM Nurses Week. These banners were hung up outside the emergency rooms of 100+ hospitals across the country.',
      shortDescription: 'Short description for image 3.',
      company: 'US Acute Care Solutions',
      link: 'https://example.com/3',
      buttonText: 'Explore Now',
    },
    {
      id: 4,
      title: 'Image 4 Title',
      images: ['/images/Midwest-Brochure.jpg'],
      description: 'This is the description for image 4.',
      shortDescription: 'Short description for image 4.',
      link: 'https://example.com/4',
      buttonText: 'See More',
    },
    {
      id: 5,
      title: '5th Annual Rubber City Classic Tournament Branding',
      images: ['/images/RCC-stack.jpg', '/images/RCC-poster.jpg', '/images/RCC-Book.jpg'],
      description: 'The Rubber City Classic is a disc golf tournament that takes place in Norton, OH every fall. I was presented with the opportunity to brand the tournament. This included the logo, any advertising, and the caddie book that was given to all players of the tournament.',
      shortDescription: '5th Annual Rubber City Classic',
      company: 'Rubber City Classic Tournament',
      link: 'https://example.com/4',
      buttonText: 'See More',
    },
    {
      id: 6,
      title: 'Restaurant Menu',
      images: ['/images/Carlos-menu.jpg'],
      description: 'Need a description here.',
      shortDescription: 'Restaurant Menu',
      company: 'Carlos Trattoria, Hartville, OH',
      link: 'https://example.com/4',
      buttonText: 'See Full Menu',
    },
  ];

  useEffect(() => {
    setShuffledGalleryData(shuffleArray(galleryData));
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      // Prevent background scrolling when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable background scrolling when modal is closed
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto'; // Cleanup when component unmounts
    };
  }, [isModalOpen]);

  const openModal = (imageData) => {
    const imagesWithoutFirst = imageData.images.slice(1); // Exclude the first image
    setModalData({ ...imageData, images: imagesWithoutFirst });
    setCurrentImageIndex(0); // Start at the first image in the modified array
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === modalData.images.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? modalData.images.length - 1 : prev - 1
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Gallery Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
        {shuffledGalleryData.map((item) => (
          <div key={item.id}>
            <img
              src={item.images[0]}
              alt={item.title}
              className="w-full h-auto object-cover rounded-lg cursor-pointer transition-opacity opacity-0 duration-500 ease-in-out"
              loading="lazy"
              onLoad={(e) => e.target.classList.remove('opacity-0')}
              onClick={() => openModal(item)}
            />
            <div className="block sm:hidden mt-2 text-center">
              <h3 className="text-xl font-semibold text-gray-900">{item.title}</h3>
              <p className="text-sm text-gray-600">{item.shortDescription}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-40">
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg max-w-4xl w-full relative overflow-y-auto max-h-[90vh]" // Enable scrolling and limit height
          >
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-black text-4xl z-50 hover:text-red-500"
              aria-label="Close modal"
            >
              ×
            </button>

            {/* Image with extra padding */}
            <div className="relative pt-20 sm:pt-6">
              <img
                src={modalData.images?.[currentImageIndex]}
                alt="Modal"
                onError={(e) =>
                  (e.target.src = 'https://placehold.co/600x400?text=Image+Not+Found')
                }
                className="w-full h-[60vh] object-contain rounded-lg mb-4"
              />
              {modalData.images && modalData.images.length > 1 && (
                <>
                  <button
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 rounded-full p-2"
                    onClick={prevImage}
                  >
                    ‹
                  </button>
                  <button
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 rounded-full p-2"
                    onClick={nextImage}
                  >
                    ›
                  </button>
                </>
              )}
            </div>

            {/* Title */}
            {modalData.title && <h2 className="text-2xl font-semibold text-gray-900 mb-1">{modalData.title}</h2>}

            {/* Company */}
            {modalData.company && <p className="text-md text-gray-500 mb-1 italic">{modalData.company}</p>}

            {/* Description */}
            {modalData.description && <p className="text-base text-gray-700 mb-4">{modalData.description}</p>}

            {/* Link */}
            {modalData.link && (
              <a
                href={modalData.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition"
              >
                {modalData.buttonText || 'Learn More'}
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Masonry;
